import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import ArrowUp from 'react-ionicons/lib/ArrowUp'
import SeoCanon from './SeoCanon'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, tag, arrowColor } = this.props

    return (
      <>
        <section className="section">
          <div className="container">
            <h1 className="is-uppercase">{title}</h1>
            <div className="columns is-multiline">
              {posts.map(({ node: post }) => (
                <div className="column is-4 is-flex" key={post.id}>
                  <div className="inner-wrapper relative is-flex bb shadow p-5">
                    <h2 className="inner-title pt-3">
                      <Link to={tag ? `/academie/${post.slug}` : post.slug}>
                        {parse(post.title)}
                      </Link>
                    </h2>
                    <div
                      className="inner-content mb-4"
                      dangerouslySetInnerHTML={{
                        __html: post.content.replace(/more.*/, ''),
                      }}
                    />
                    <Link
                      to={tag ? `/academie/${post.slug}` : post.slug}
                      className="more-arrow"
                    >
                      <ArrowUp color={arrowColor} height="30px" width="30px" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {tag ? (
              <div className="meer-link has-text-centered">
                <Link to="/academie/">ALLE ARTIKELEN</Link>
              </div>
            ) : null}
          </div>
        </section>
        {title === 'Academie' ? <SeoCanon /> : null}
      </>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on WpPost {
    id
    title
    content
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
